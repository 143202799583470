import { alpha } from '@mui/material/styles';
import { LAYOUT_VERSION } from '../config-global';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  lighter: '#F9FAFB',
  light: '#C4CDD5',
  main: '#637381',
  dark: '#454F5B',
  darker: '#161C24',
  contrastText: '#FFFFFF',
};

const PRIMARY = {
  lighter: '#ebf1fc',
  light: '#76B0F1',
  main: '#004bda',
  dark: '#103996',
  darker: '#061B64',
  contrastText: '#FFFFFF',
};

// const SECONDARY = {
//   lighter: '#EFD6FF',
//   light: '#C684FF',
//   main: '#8E33FF',
//   dark: '#5119B7',
//   darker: '#27097A',
//   contrastText: '#FFFFFF',
// };

const ACCENT = {
  lighter: '#FEE9D1',
  light: '#FDAB76',
  main: '#FA541C',
  dark: '#B3200E',
  darker: '#770508',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const DYNAMIC_PALETTE = {
  leadspark: {
    accent:
      {
        lighter: '#CAFDF5',
        light: '#61F3F3',
        main: '#00B8D9',
        dark: '#006C9C',
        darker: '#003768',
        contrastText: '#FFFFFF',
      },
    primary: {
      lighter: '#ebf1fc',
      light: '#76B0F1',
      main: '#004bda',
      dark: '#103996',
      darker: '#061B64',
      contrastText: '#FFFFFF',
    },
  },
  lazzaweb: {
    accent:
      {
        lighter: '#ebf1fc',
        light: '#457fff',
        main: '#457fff',
        dark: '#103996',
        darker: '#061B64',
        contrastText: '#FFFFFF',
      },
    primary: {
      lighter: '#ebf1fc',
      light: '#457fff',
      main: '#3274ff',
      dark: '#103996',
      darker: '#061B64',
      contrastText: '#FFFFFF',
    },
    secondary: {
      lighter: '#ebf1fc',
      light: '#457fff',
      main: '#457fff',
      dark: '#103996',
      darker: '#061B64',
      contrastText: '#FFFFFF',
    },
    // secondary: {
    //   lighter: '#d3f5eb',
    //   light: '#7AE5C8',
    //   main: '#4cceab',
    //   dark: '#239f7f',
    //   darker: '#149b79',
    //   contrastText: '#ffffff',
    // },
  },
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: ACCENT,
  accent: ACCENT,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.36),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode) {
  const commonPalette = COMMON;

  if (DYNAMIC_PALETTE[LAYOUT_VERSION]) {
    if (DYNAMIC_PALETTE[LAYOUT_VERSION]?.primary) {
      commonPalette.primary = DYNAMIC_PALETTE[LAYOUT_VERSION].primary;
    }
    if (DYNAMIC_PALETTE[LAYOUT_VERSION]?.secondary) {
      commonPalette.secondary = DYNAMIC_PALETTE[LAYOUT_VERSION].secondary;
    }
    if (DYNAMIC_PALETTE[LAYOUT_VERSION]?.accent) {
      commonPalette.accent = DYNAMIC_PALETTE[LAYOUT_VERSION].accent;
    }
  }

  const light = {
    ...commonPalette,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: '#f5f5f5',
      light: '#f9fafb',
      dark: alpha(GREY[300], 0.5),
    },
    action: {
      ...commonPalette.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...commonPalette,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
      light: GREY[900],
      dark: alpha(GREY[500], 0.06),
    },
    action: {
      ...commonPalette.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
